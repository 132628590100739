import { LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  useToast,
  Heading,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Button,
  Divider
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import {  admin_Login } from "../api";
import { AuthContext } from "../context/AuthContext";
import GoogleSignInButton from "../components/GoogleSignInButton"
const jwt = require('jsonwebtoken');


const Login = () => {
  const { checkAuthState, logInUser, setUser } = useContext(AuthContext);

  useEffect(() => {
    checkAuthState();
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loginButtonLoading, setLoginButtonLoading] = useState(false);
  const toast = useToast();
  const [showAdminLogin, setShowAdminLogin] = useState(false)
  const togglePasswordVisibility = () => setShow(!show);


  const _validate = (email, password) => {
    if (!email?.trim() || !password) {
      toast({
        title: "Error",
        description: "Please input all the fields.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      adminLogin(email?.trim(), password);
    }
  };

  const adminLogin = async (email, password) => {
    try {
      const PAYLOAD = { email, password };
      setLoginButtonLoading(true);
      const res = await admin_Login(PAYLOAD);
      if (res?.token) {
        logInUser(res?.token);
        const decodedToken = jwt.decode(res?.token);
        const _userData = {
          name: decodedToken.name,
          photo: decodedToken.photo,
          email: decodedToken.email,
          type: decodedToken.type,
        };
        setUser(_userData);
      }
    } catch (e) {
      toast({
        title: "Error",
        description:
          e?.response?.data?.error ?? "Something went wrong, please try again",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setLoginButtonLoading(false);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    _validate(email, password);
  };

  return (
    <>
      <Box px={10}>
        <Center sx={{ height: "80vh" }}>
          {showAdminLogin ? (
            <Box
              shadow={"md"}
              p={10}
              border="1px"
              borderColor="gray.200"
              borderRadius="5px"
            >
              <Center mb={5}>
                <LockIcon mr={1} />
                <Heading as="b" size="md">
                  Admin Log In
                </Heading>
              </Center>
              <form onSubmit={onSubmit}>
                <FormControl id="username" mb={5}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type={"text"}
                    placeholder="Enter email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl id="password" mb={5}>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={show ? "text" : "password"}
                      placeholder="Enter password"
                      name="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement>
                      <Button onClick={togglePasswordVisibility}>
                        {show ? <ViewOffIcon /> : <ViewIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Center>
                  <VStack>
                    <Button
                      colorScheme="green"
                      isLoading={loginButtonLoading}
                      type="submit"
                    >
                      Login
                    </Button>
                    <Button onClick={() => setShowAdminLogin(false)}>Back</Button>
                  </VStack>
                </Center>
              </form>
            </Box>
          ) : (
            <Box>
              <GoogleSignInButton />
              <Divider />
              <Center sx={{ height: "9vh" }}>
                <Button
                  colorScheme="green"
                  onClick={() => setShowAdminLogin(true)}
                >
                  Admin LogIn
                </Button>
              </Center>
            </Box>
          )}
        </Center>
      </Box>

    </>
  );
};

export default Login;
