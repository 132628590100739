import axios from "axios";

//const API_BASE_URL = `${window.location.protocol}//${window.location.host}`;
const API_BASE_URL = `https://api.storemeall.com`;
// const API_BASE_URL = `http://localhost:5007`;
const ACCESS_TOKEN_STRING = "access_token";
const ACCESS_TOKEN_HEADER_STRING = "x-access-token";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

const networkErrorLogger = (e, URL, PAYLOAD, isAuth = true) => {
  console.log(`Request: ${URL} with payload: ${JSON.stringify(PAYLOAD)} failed! `);

  if (e?.message === "Network Error") {
    throw new Error("Network Error. Ensure you are connected to internet.");
  } else if (isAuth && e?.response?.status === 401) {
    window.localStorage.removeItem(ACCESS_TOKEN_STRING);
    window.location.reload();
  } else {
    throw e;
  }
};

const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_STRING);

const setUpConfig = (isAuth) => {
  const DEFAULT_CONFIG = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (isAuth) {
    DEFAULT_CONFIG.headers[ACCESS_TOKEN_HEADER_STRING] = getAccessToken();
    return DEFAULT_CONFIG;
  } else {
    return DEFAULT_CONFIG;
  }
};

const get = async (URL, isAuth = true) => {
  try {
    const CONFIG = setUpConfig(isAuth);
    const result = await axiosInstance.get(URL, CONFIG);

    return result.data;
  } catch (e) {
    networkErrorLogger(e, URL, "nil", isAuth);
  }
};

const post = async (URL, PAYLOAD = {}, isAuth = true) => {
  try {
    const CONFIG = setUpConfig(isAuth);
    const result = await axiosInstance.post(URL, PAYLOAD, CONFIG);
    return result.data;
  } catch (e) {
    networkErrorLogger(e, URL, PAYLOAD, isAuth);
  }
};

const _delete = async (URL, isAuth = true) => {
  try {
    const CONFIG = setUpConfig(isAuth);
    const result = await axiosInstance.delete(URL, CONFIG);
    return result.data;
  } catch (e) {
    networkErrorLogger(e, URL, null, isAuth);
  }
};

export const getPutUrl = async (data) => {
  const URL = API_BASE_URL + `/list/url`;
  try {
    const config = {
      headers: {
        "x-access-token": getAccessToken(),
      },
    };

    const result = await axiosInstance.post(URL, data, config);

    return result.data;
  } catch (e) {
    networkErrorLogger(e, URL, data);
  }
};

export const uploadtoS3 = async (url, data, options, type) => {
  const URL = url;

  try {
    const config = {
      headers: {
        Accept: "*/*",
        "Content-Type": type,
        "Accept-Encoding": "gzip, deflate, br",
      },
      ...options,
    };

    const result = await axios.put(URL, data, config);

    return result.data;
  } catch (e) {
    console.log("this is error", e);
    networkErrorLogger(e, URL, data);
  }
};

export const createFile = async (data) => {
  const URL = API_BASE_URL + `/list/file`;
  try {
    const config = {
      headers: {
        "x-access-token": getAccessToken(),
      },
    };
    const result = await axios.post(URL, data, config);
  } catch (e) {
    networkErrorLogger(e);
  }
};

export const login = (PAYLOAD) => {
  const URL = API_BASE_URL + `/users/login`;
  return post(URL, PAYLOAD, false);
};

export const admin_Login = (PAYLOAD) => {
  const URL = API_BASE_URL + `/users/admin/login`;
  return post(URL, PAYLOAD, false);
};

export const list = (page = "0", size = 20, folder, keyword, fromDate, toDate, userId) => {
  const URL =
    API_BASE_URL +
    `/list/?size=${size}&page=${page}${folder ? `&folder=${folder}` : ""}${
      keyword ? `&keyword=${keyword}` : ""
    }${fromDate ? `&fromDate=${fromDate}` : ""}${toDate ? `&toDate=${toDate}` : ""}${
      userId ? `&userId=${userId}` : ""
    }`;
  return get(URL);
};

export const createFolder = (folderName, folderPath) => {
  const URL = API_BASE_URL + `/list/folder`;
  return post(URL, { folder: folderPath, name: folderName });
};

export const deleteFile = (fileId) => {
  const URL = API_BASE_URL + `/list/${fileId}`;
  return _delete(URL);
};

export const folderStatus = (fileId) => {
  const URL = API_BASE_URL + `/list/folderStatus/${fileId}`;
  return get(URL);
};

export const renameFile = (fileId, name) => {
  const URL = API_BASE_URL + `/list/rename`;
  return post(URL, { id: fileId, name: name });
};

export const getSignedUrl = (_id) => {
  const URL = API_BASE_URL + `/list/getUrl/${_id}`;
  return get(URL);
};

export const getSignedUrlPreview = (_id) => {
  const URL = API_BASE_URL + `/list/gePreviewUrl/${_id}`;
  return get(URL);
};

export const signInWithGoogle = (token) => {
  const URL = API_BASE_URL + `/users/auth/google`;
  return post(URL, { idToken: token });
};

export const getDeleteList = () => {
  const URL = API_BASE_URL + `/list/deleteList`;
  return get(URL);
};

export const hardDelete = (fileId) => {
  const URL = API_BASE_URL + `/list/delete/${fileId}`;
  return _delete(URL);
};

export const getUsers = () => {
  const URL = API_BASE_URL + `/list/users`;
  return get(URL);
};

export const userAccess = (id, access) => {
  const URL = API_BASE_URL + `/users/access`;
  return post(URL, { _id: id, access: access });
};

export const getTeam = () => {
  const URL = API_BASE_URL + `/team`;
  return get(URL);
};

export const createTeam = (name, ids) => {
  const URL = API_BASE_URL + `/team`;
  return post(URL, { name: name, ids: ids });
};

export const deleteTeam = (_id) => {
  const URL = API_BASE_URL + `/team/${_id}`;
  return _delete(URL);
};

export const editTeam = (_id, name, ids) => {
  const URL = API_BASE_URL + `/team/edit`;
  return post(URL, { _id: _id, name: name, ids: ids });
};

export const editFile = (id, tags, description, shortDescription) => {
  const URL = API_BASE_URL + `/list/edit`;
  return post(URL, {
    id: id,
    tags: tags,
    shortDescription: shortDescription,
    description: description,
  });
};

export const getFolders = (path) => {
  const URL = API_BASE_URL + `/list/getFolders/${path}`;
  return get(URL);
};

export const moveFile = (fileIds, path) => {
  const URL = API_BASE_URL + `/list/moveFile`;
  return post(URL, { fileids: fileIds, path: path });
};
