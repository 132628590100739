import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    InputGroup,
    Text,
    HStack,
    IconButton,
    Tooltip,
    useToast,
    useDisclosure,
    Avatar,
    Stack,
    Box
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { createTeam, getUsers, getTeam, deleteTeam, editTeam } from "../api";
import MultiSelect from "./MultiSelect";
import { FaTrash, FaPlus, FaEdit, FaUsers  } from "react-icons/fa";


const Team = () => {
    const [showNewTeamRow, setShowNewTeamRow] = useState(false);
    const [showEditTeamRow, setShowEditTeamRow] = useState(false);
    const [newTeamName, setNewTeamName] = useState("");
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [users, setUsers] = useState();
    const [teams, setTeams] = useState();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await getUsers();
                let data =
                    response.data?.map((user) => ({
                        key: user._id,
                        email: user.email,
                        name: user.email,
                        href: "#",
                        src: user.photo?.toString() || "",
                    })) || [];
                setUsers(data);
                response = await getTeam();
                data =
                    response.data?.map((team) => ({
                        _id: team._id,
                        name: team.name,
                        members: team.members
                    })) || [];
                setTeams(data);
            } catch (error) {
                errorToast(error);
            }
        };

        fetchData();
    }, []);

    const handleAddNewTeam = () => {
        setShowNewTeamRow(true);
    };

    const handleSaveNewTeam = async () => {
        setNewTeamName("");
        setShowNewTeamRow(false);
        const ids = selectedUsers.map(item => item.key);
        try {
            await createTeam(newTeamName, ids);
        } catch (error) {
            errorToast(error);
        } finally {
            try {
                let response = await getTeam();
                let data =
                    response.data?.map((team) => ({
                        _id: team._id,
                        name: team.name,
                        members: team.members
                    })) || [];
                setTeams(data);
                setSelectedUsers([]);
            } catch (error) {
                errorToast(error);
            }
        }
    };

    const handleCancelNewTeam = () => {
        setNewTeamName("");
        setShowNewTeamRow(false);
        setShowEditTeamRow(false);
        setSelectedUsers([]);
    };

    const handleDeleteTeam = async (id) => {
        try {
            await deleteTeam(id);
            successToast('Team Deleted')
        } catch (error) {
            errorToast(error);
        } finally {
            try {
                let response = await getTeam();
                let data =
                    response.data?.map((team) => ({
                        _id: team._id,
                        name: team.name,
                        members: team.members
                    })) || [];
                setTeams(data);
            } catch (error) {
                errorToast(error);
            }
        }
    };

    const handleEditTeam = async (_id, name, members) => {
        setSelectedTeamId(_id)
        setShowEditTeamRow(true)
        setNewTeamName(name);
        setSelectedUsers(members)
    };

    const editData = async (_id) => {
        try {
            setShowEditTeamRow(false);
            setNewTeamName("");
            const ids = selectedUsers.map(item => item.key);
            try {
                await editTeam(_id, newTeamName, ids);
            } catch (error) {
                errorToast(error);
            } finally {
                try {
                    let response = await getTeam();
                    let data =
                        response.data?.map((team) => ({
                            _id: team._id,
                            name: team.name,
                            members: team.members
                        })) || [];
                    setTeams(data);
                    setSelectedTeamId('')
                    setSelectedUsers([]);
                } catch (error) {
                    errorToast(error);
                }
            }
        } catch (error) {
            errorToast()
        }
    }

    const errorToast = (message) =>
        toast({
            title: "Error",
            description: message ?? "Something went wrong, please try again",
            status: "error",
            duration: 5000,
            isClosable: true,
        });

    const successToast = (message) =>
        toast({
            title: "Done",
            description: message ?? "Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
        });

    return (
        <>
            <Button onClick={onOpen} leftIcon={<FaUsers />}>
                Teams
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW="1200px" h="500px" overflow="auto">
                    <ModalHeader>
                        <HStack padding={"16px 12px"}>
                            <Text>Teams</Text>
                            <Button
                                onClick={handleAddNewTeam}
                                leftIcon={<FaPlus />}
                                isDisabled={showNewTeamRow}
                            >
                                Teams
                            </Button>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <Table mt={"12px"} size="md">
                        <Thead>
                            <Tr>
                                <Th
                                    width={"60%"}
                                    cursor={"pointer"}
                                    padding={"0px"}
                                    _active={{ transform: "translateY(-1px)" }}
                                >
                                    <HStack padding={"16px 12px"}>
                                        <Text>Team Name</Text>
                                    </HStack>
                                </Th>
                                <Th
                                    width={"60%"}
                                    cursor={"pointer"}
                                    padding={"0px"}
                                    _active={{ transform: "translateY(-1px)" }}
                                >
                                    <HStack padding={"16px 12px"}>
                                        <Text>Members</Text>
                                    </HStack>
                                </Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {teams && teams.map(({ _id, name, members }) => (
                                <Tr key={_id}>
                                    <Td>
                                        {selectedTeamId === _id && showEditTeamRow ? (
                                            <InputGroup size="sm">
                                                <Input
                                                    type="text"
                                                    placeholder="Enter team name"
                                                    value={newTeamName}
                                                    onChange={(e) => setNewTeamName(e.target.value)}
                                                />
                                            </InputGroup>
                                        ) : (
                                            <Text>{name}</Text>
                                        )}
                                    </Td>
                                    <Td>
                                        {selectedTeamId === _id && showEditTeamRow ? (
                                            <Td>
                                                <MultiSelect
                                                    selectedData={selectedUsers}
                                                    multiselectData={(users) => setSelectedUsers(users)}
                                                />
                                            </Td>
                                        ) : (
                                            <Box mt="1">
                                                <Stack direction="row" flexWrap="wrap" spacing="2">
                                                    {members.map((user) => (
                                                        <Box key={user.key}>
                                                            <AvatarButton
                                                                user={user}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Stack>
                                            </Box>
                                        )}
                                    </Td>
                                    <Td textAlign="right">
                                        {selectedTeamId === _id && showEditTeamRow ? (
                                            <HStack>
                                                <Button size="sm" onClick={() => {editData(_id)}} >
                                                    Save
                                                </Button>
                                                <Button size="sm" onClick={handleCancelNewTeam}>
                                                    Cancel
                                                </Button>
                                            </HStack>
                                        ) : (
                                            <HStack>
                                                <Tooltip label="Edit">
                                                    <IconButton
                                                        icon={<FaEdit />}
                                                        size="sm"
                                                        onClick={() => handleEditTeam(_id, name, members)}
                                                    />
                                                </Tooltip>
                                                <Tooltip label="Delete">
                                                    <IconButton
                                                        icon={<FaTrash />}
                                                        size="sm"
                                                        onClick={() => handleDeleteTeam(_id)}
                                                    />
                                                </Tooltip>
                                            </HStack>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                            {showNewTeamRow && (
                                <Tr>
                                    <Td>
                                        <InputGroup size="sm">
                                            <Input
                                                type="text"
                                                placeholder="Enter team name"
                                                value={newTeamName}
                                                onChange={(e) => setNewTeamName(e.target.value)}
                                            />
                                        </InputGroup>
                                    </Td>
                                    <Td>
                                        <Td>
                                            <MultiSelect
                                                multiselectData={(users) => setSelectedUsers(users)}
                                            />
                                        </Td>
                                    </Td>
                                    <Td textAlign="right">
                                        <HStack>
                                            <Button size="sm" onClick={handleSaveNewTeam}>
                                                Save
                                            </Button>
                                            <Button size="sm" onClick={handleCancelNewTeam}>
                                                Cancel
                                            </Button>
                                        </HStack>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </ModalContent >
            </Modal >
        </>
    );
};

const AvatarButton = ({ user }) => (
    <Tooltip label={user.email} aria-label="User email">
        <Avatar name={user.email} src={user.src} size="sm" />
    </Tooltip>
);

export default Team;
