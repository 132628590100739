const fileTypes = {
  jpg: "image",
  cr2: "image",
  jfif: "image",
  jpeg: "image",
  gif: "image",
  png: "image",
  mp4: "video",
  mkv: "video",
  mp3: "audio",
  JPG: "image",
  CR2: "image",
  JFIF: "image",
  JPEG: "image",
  GIF: "image",
  PNG: "image",
  MP4: "video",
  MKV: "video",
  mov: "video",
  MOV: "video",
  MOV: "video",
  MP3: "audio",
};

export default fileTypes;
