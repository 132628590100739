import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  chakra,
  HStack,
  Flex,
  useColorModeValue,
  Heading,
  Tooltip,
  Avatar,
  Box,
  Divider,
  Text
} from "@chakra-ui/react";
import { useContext, useState, useEffect, useRef } from "react";
import { FcAddDatabase } from "react-icons/fc";
import ToggleTheme from "../components/ToggleTheme";
import { AuthContext } from "../context/AuthContext";
import SearchBar from "./SearchBar";
import { Link } from "react-router-dom";
import Package from "../../package.json";
import { MdExitToApp } from "react-icons/md";

const Header = () => {
  const { isLoggedIn, logOutUser, userData } = useContext(AuthContext);
  const [islogOutOpen, setIslogOutOpen] = useState(false);
  const bg = useColorModeValue("white", "gray.800");
  const version = Package.version;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  return (
    <chakra.header
      shadow={"sm"}
      transition="box-shadow 0.2s"
      pos="fixed"
      top="0"
      zIndex="3"
      left="0"
      right="0"
      bg={bg}
      width="full"
      py="8px"
    >
      <Flex
        px={"80px"}
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"56px"}
      >
        <Link to={"/"}>
          <Tooltip label={`v${version}`}>
            <HStack>
              <FcAddDatabase size={"26px"} />
              <Heading size={"md"}>File Store</Heading>
            </HStack>
          </Tooltip>
        </Link>
        {isLoggedIn && (
          <HStack position="relative">
            <SearchBar />
            <ToggleTheme />
            <Button onClick={toggleDropdown} size="md">
              <Avatar size="sm" src={userData.photo} />
            </Button>
            {isDropdownOpen && (
              <UserDropdown setIslogOutOpen={setIslogOutOpen} logOutUser={logOutUser} islogOutOpen={islogOutOpen} userData={userData} />
            )}
          </HStack>
        )}
      </Flex>
    </chakra.header>
  );
};

const LogOutButton = ({ logOutUser, setIslogOutOpen, islogOutOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIslogOutOpen(false);

  useEffect(() => {
    setIsOpen(islogOutOpen)
  }, [islogOutOpen]);

  return (
    <>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="md" fontWeight="bold">
              Log out
            </AlertDialogHeader>
            <AlertDialogBody fontSize="md">
              Are you sure you want to Log out?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose} size="sm">
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => {
                  onClose();
                  logOutUser();
                }}
                ml={3}
              >
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const UserDropdown = ({ logOutUser, setIslogOutOpen, islogOutOpen, userData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const bg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const dropdownRef = useRef(null);

  const handleButtonClick = () => {
    setIsOpen(false);
    setIslogOutOpen(true);
  };

  useEffect(() => {
    setIsOpen(true)
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleEscapeKey);
    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [dropdownRef]);

  return (
    <>
      {isOpen && (
        <>
          <Box
            position="fixed"
            top="0"
            right="0"
            bottom="0"
            left="0"
            bg="gray.500"
            opacity="0.3"
            zIndex="3"
            onClick={() => setIsOpen(false)}
          />
          <Box
            position="absolute"
            top="56px"
            right="0"
            zIndex="3"
            bg={bg}
            w="240px"
            boxShadow="sm"
            py="2"
            rounded="md"
            ref={dropdownRef}
          >
            <Flex px="3" py="2" alignItems="center">
              <Box mr="2">
                <img
                  src={userData.photo}
                  alt="User Avatar"
                  width="32"
                  height="32"
                  style={{ borderRadius: "50%" }}
                />
              </Box>
              <Box>
                <Text fontWeight="semibold" color={textColor}>
                {userData.name}
                </Text>
                <Text fontSize="sm" color="gray.500">
                {userData.email}
                </Text>
              </Box>
            </Flex>
            <Divider />
            <Button
              variant="ghost"
              w="full"
              justifyContent="flex-start"
              py="2"
              px="3"
              leftIcon={<MdExitToApp />}
              onClick={handleButtonClick}
            >
              Log out
            </Button>
          </Box>
        </>
      )}
      {islogOutOpen && (
        <>
          <LogOutButton setIslogOutOpen={setIslogOutOpen} logOutUser={logOutUser} islogOutOpen={islogOutOpen} />
        </>
      )}
    </>
  );
};


export default Header;
