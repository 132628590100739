import { Divider, Flex, HStack, Button, Skeleton, Text } from "@chakra-ui/react";
import { useContext } from "react";
import BreadCrumb from "../components/BreadCrumb";
import NewFolder from "../components/NewFolder";
import Delete from "../components/Delete";
import Team from "../components/Team";
import Move from "../components/Move";
import RestrictUser from "../components/RestrictUser";
import UploadButton from "../components/UploadButton";
import StorageTable from "../components/StorageTable";
import PageSwitcher from "../components/PageSwitcher";
import DatePicker from "../components/DatePicker";
import UserFilter from "../components/UserFilter";
import { AppContext } from "../context/AppContext";
import { AuthContext } from "../context/AuthContext";
import ViewFile from "../components/ViewFile";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

const Home = () => {
  const { listLoading, fileId, findPreviousFile, findNextFile, filesInPage, selectedRows } =
    useContext(AppContext);
  const { userData } = useContext(AuthContext);
  const currentFileIdx = filesInPage.findIndex((item) => item === fileId);

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <BreadCrumb />
        {fileId && (
          <HStack>
            <Button
              isDisabled={currentFileIdx === 0}
              onClick={findPreviousFile}
              size="sm"
            >
              <ArrowBackIcon />
            </Button>
            <Button
              isDisabled={currentFileIdx === filesInPage?.length - 1}
              onClick={findNextFile}
              size="sm"
            >
              <ArrowForwardIcon />
            </Button>
          </HStack>
        )}
      </Flex>
      <Divider my={"16px"}></Divider>
      {fileId ? (
        <ViewFile isLoading={listLoading} />
      ) : (
        <>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            my={"12px"}
          >
            <Skeleton isLoaded={!listLoading}>
              <HStack>
                <PageSwitcher />
                <DatePicker />
                <UserFilter />
              </HStack>
            </Skeleton>
            <Skeleton isLoaded={!listLoading}>
              <HStack>
                <Text>{userData.access}</Text>
                { userData.type === 'admin' ? (<><Delete /> <Team /> <RestrictUser /> <NewFolder /> <UploadButton /></>) : null }
                { selectedRows.length > 0 ? <Move /> : null}
                { userData.access === true ? (<> <NewFolder /> <UploadButton /> </>) : null }
              </HStack>
            </Skeleton>
          </Flex>
          <StorageTable isLoading={listLoading} />
        </>
      )}
    </>
  );
};

export default Home;
