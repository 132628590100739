import {
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    HStack,
    useColorMode,
    Box,
    Badge,
} from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import { getUsers, userAccess } from "../api";
import { AppContext } from "../context/AppContext";
import { FaKey } from "react-icons/fa";
import { BiCheckCircle, BiError } from "react-icons/bi";
import Avatar from '@atlaskit/avatar';


const RestrictUser = () => {
    const { colorMode } = useColorMode();
    const { deleteList } = useContext(AppContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getUsers();
                const data =
                    response.data?.map((user) => ({
                        _id: user._id,
                        email: user.email,
                        access: user.access,
                        photo: user.photo?.toString() || "",
                    })) || [];
                setUsers(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const changeAccess = async (_id, access) => {
        try {
            await userAccess(_id, access);
        } catch (error) {
            console.log(error);
        }
        finally {
            try {
                const response = await getUsers();
                const data =
                    response.data?.map((user) => ({
                        _id: user._id,
                        email: user.email,
                        access: user.access,
                        photo: user.photo?.toString() || "",
                    })) || [];
                setUsers(data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <>
            <Button onClick={onOpen} leftIcon={<FaKey />}>
                User Access
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW="1200px" h="500px" overflow="auto">
                    <ModalHeader>User Access</ModalHeader>
                    <ModalCloseButton />
                    <Table mt={"12px"} size="md">
                        <Thead>
                            <Tr>
                                <Th
                                    width={"60%"}
                                    cursor={"pointer"}
                                    padding={"0px"}
                                    _active={{ transform: "translateY(-1px)" }}
                                >
                                    <HStack padding={"16px 12px"}>
                                        <Text>Name</Text>
                                    </HStack>
                                </Th>
                                <Th
                                    width={"20%"}
                                    cursor={"pointer"}
                                    padding={"0px"}
                                    _active={{ transform: "translateY(-1px)" }}
                                >
                                    <HStack padding={"16px 12px"}>
                                        <Text>Access</Text>
                                    </HStack>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users.map((item) => (
                                <Tr
                                    _hover={{ bg: colorMode === "light" ? "gray.50" : "#141214" }}
                                    key={item._id}
                                    cursor={"pointer"}
                                >
                                    <Td
                                        display={"flex"}
                                        alignItems={"center"}
                                        padding={"16px 12px"}
                                        _hover={{ textDecoration: "underline" }}
                                    >
                                        <HStack>
                                            <Avatar src={item.photo} size="medium" />
                                            <Text ml={"8px"}>{item.email}</Text>
                                        </HStack>

                                    </Td>
                                    <Td padding={"16px 12px"}>
                                        {item.access ? (
                                            <Box as="span" color="green.500">
                                                <BiCheckCircle />
                                            </Box>
                                        ) : (
                                            <Box as="span" color="red.500">
                                                <BiError />
                                            </Box>
                                        )}
                                    </Td>
                                    <Td padding={"16px 12px"}>
                                        {item.access ? (
                                            <Button colorScheme="green" onClick={() => {
                                                changeAccess(item._id, false)
                                            }}
                                            >
                                                Block
                                            </Button>
                                        ) : (
                                            <Button colorScheme="red" onClick={() => {
                                                changeAccess(item._id, true)
                                            }}
                                            >
                                                Unblock
                                            </Button>
                                        )}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </ModalContent>
            </Modal>
        </>
    );
};

export default RestrictUser;
