import { ChakraProvider } from "@chakra-ui/react";
import { AuthContextProvider } from "./context/AuthContext";
import RootRoute from "./routes/RootRoute";

function App() {
  return (
    <ChakraProvider>
      <AuthContextProvider>
        <RootRoute />
      </AuthContextProvider>
    </ChakraProvider>
  );
}

export default App;
