import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FcOpenedFolder } from "react-icons/fc";
import { createFolder } from "../api";
import { AppContext } from "../context/AppContext";

const NewFolder = () => {
  const toast = useToast();
  const [folderName, setFolderName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { _getList, path } = useContext(AppContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const errorToast = (message) =>
    toast({
      title: "Error",
      description: message ?? "Something went wrong, please try again",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

  const successToast = (message) =>
    toast({
      title: "Done",
      description: message ?? "Action is completed",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

  const createNewFolder = async (e) => {
    e.preventDefault();

    const isNameError = folderName?.trim()?.length === 0;

    setNameError(isNameError);

    if (isNameError) return;

    setLoading(true);
    try {
      await createFolder(folderName, path);
      setLoading(false);
      onClose();
      successToast("Folder created");
    } catch (e) {
      setLoading(false);
      console.log(e?.response ?? e);
      onClose();
      errorToast(e?.response?.data?.error);
    } finally {
      _getList();
    }
  };

  return (
    <>
      <Button onClick={onOpen} leftIcon={<FcOpenedFolder size={"20px"} />}>
        New Folder
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Folder</ModalHeader>
          <ModalCloseButton />
          <form>
            <ModalBody>
              <FormControl isInvalid={nameError}>
                <Input
                  placeholder="Folder name"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  autoFocus
                />
                <FormErrorMessage>Folder name is required</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                onClick={createNewFolder}
                isLoading={loading}
              >
                Create
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewFolder;
