import React, { useContext } from "react";
import { Button, HStack, useColorMode  } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./css/datePicker.css"
import { AppContext } from "../context/AppContext";


function Datepicker() {
    const {
        setFromDate,
        setToDate,
        fromDate,
        toDate,
    } = useContext(AppContext);

    const changeFromDate = (date) => {
        setFromDate(date);
    };

    const changeToDate = (date) => {
        setToDate(date);
    };


    const handleClearClick = () => {
        setFromDate(null);
        setToDate(null);
    };

    const { colorMode } = useColorMode();

    return (
        <HStack>
            <DatePicker
                selected={fromDate}
                onChange={changeFromDate}
                dateFormat="dd/MM/yyyy"
                className={`${colorMode === "dark" ? "dark" : "datepicker"}`}
                placeholderText="Range From"
            />
            <h3>To</h3>
            <DatePicker
                selected={toDate}
                onChange={changeToDate}
                dateFormat="dd/MM/yyyy"
                className={`${colorMode === "dark" ? "dark" : "datepicker"}`}
                placeholderText="Range To"
            />
            {fromDate || toDate ? (
                <Button size="sm" onClick={handleClearClick}>Clear</Button>
            ) : null}
        </HStack>
    );
}

export default Datepicker;
