import { useToast } from "@chakra-ui/react";
import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import {
  list as listAPI,
  getDeleteList,
  getFolders,
  createFile,
  getPutUrl,
  uploadtoS3,
} from "../api";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import { AuthContext } from "./AuthContext";

export const AppContext = createContext({
  list: [],
});

const homeBreadCrumboption = { link: "/", title: "Home" };
const defaultSortConfig = { key: "", direction: null };
const pageSizeOptions = [3, 20, 50, 100];
const fileStorageBaseUrl = process.env.REACT_APP_FILE_STORAGE_BASE_URL;

export const AppContextProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const toast = useToast();
  const query = useQuery();

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions[1]);
  const [searchText, setSearchText] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [searchList, setSearchList] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearId, setClearId] = useState();
  const [path, setPath] = useState(pathname.split("/").filter(Boolean).join(","));
  const [fileId, setFileId] = useState(false);
  const [fileDetails, setFileDetails] = useState({});
  const [breadCrumb, setBreadCrumb] = useState([homeBreadCrumboption]);
  const [sortConfig, setSortConfig] = useState(defaultSortConfig);
  const [filesInPage, setFilesInPage] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [totalUpload, setTotalUpload] = useState(0);
  const [currentUpload, setCurrentUpload] = useState(0);

  const totalPages = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);

  useEffect(() => {
    if (!searchText?.trim()) return;

    if (clearId) {
      clearTimeout(clearId);
    }

    const _clearId = setTimeout(_getSearchList, 300);

    setClearId(_clearId);

    return () => clearTimeout(clearId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (clearId) {
      clearTimeout(clearId);
    }

    const _clearId = setTimeout(_getList, 300);

    setClearId(_clearId);

    return () => clearTimeout(clearId);
  }, [toDate]);

  useEffect(() => {
    if (clearId) {
      clearTimeout(clearId);
    }

    const _clearId = setTimeout(_getList, 300);

    setClearId(_clearId);

    return () => clearTimeout(clearId);
  }, [selectedUser]);

  useEffect(() => {
    const dirPath = pathname.split("/").filter(Boolean).join(",");
    const file = query.get("file") ?? null;

    setFileId(file);
    computeBreadCrumb(dirPath, file);
    if (file) {
      const __dirPath = pathname.split("/").filter(Boolean);
      __dirPath.pop();
      setPath(__dirPath.join(","));
    } else {
      setPage(1);
      setPath(dirPath);
    }
  }, [pathname]);

  useEffect(() => {
    _getList();
  }, [path, page, pageSize]);

  useEffect(() => {
    const fileToSet = list.find((item) => item._id === fileId) || {};

    const fileStorageUrl =
      fileStorageBaseUrl +
      `${fileToSet?.path?.length ? "/" + fileToSet?.path?.join("/") : ""}/${fileToSet?.name}`;

    const previewfileStorageUrl =
      fileStorageBaseUrl +
      `${fileToSet?.path?.length ? "/" + fileToSet?.path?.join("/") : ""}/${
        fileToSet?.preview ?? fileToSet?.name
      }`;

    fileToSet.location = fileStorageUrl;
    fileToSet.previewlocation = previewfileStorageUrl;

    setFileDetails(fileToSet);
  }, [fileId, list]);

  useEffect(() => {
    const listSorted = sortList(list);
    setList(listSorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig]);

  useEffect(() => {
    const _filesInPage = list.filter((item) => !item.is_folder).map((item) => item._id);

    setFilesInPage(_filesInPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const sortList = (list) =>
    [...list].sort((a, b) => {
      if (a[sortConfig.key]?.toLowerCase() < b[sortConfig.key]?.toLowerCase()) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key]?.toLowerCase() > b[sortConfig.key]?.toLowerCase()) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

  const _deleteList = async () => {
    try {
      const data = await getDeleteList();
      setDeleteList(data);
    } catch (e) {
      console.log(e?.response ?? e);
      errorToast(e?.response?.data?.error);
    }
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig?.key === key && sortConfig?.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const navigate = (item) => {
    const navigationPath = `/${item?.path?.length ? item.path.join("/") + "/" : ""}${item?.name}${
      item?.is_folder ? "" : `?file=${item._id}`
    }`;
    history.push(navigationPath);
  };

  const changePageSize = (size) => {
    setPageSize(size);
    setPage(1);
  };

  const findNextFile = () => {
    const currentFileIdx = filesInPage.findIndex((item) => item === fileId);
    const nextFileId = filesInPage[currentFileIdx + 1];

    if (!nextFileId) return;

    const nextFile = list.find((item) => item._id === nextFileId);
    navigate(nextFile);
  };

  const findPreviousFile = () => {
    const currentFileIdx = filesInPage.findIndex((item) => item === fileId);
    const prevFileId = filesInPage[currentFileIdx - 1];

    if (!prevFileId) return;

    const prevFile = list.find((item) => item._id === prevFileId);
    navigate(prevFile);
  };

  const computeBreadCrumb = (dirPath, fileId) => {
    const __breadCrumb = [homeBreadCrumboption];

    const directoryPaths = dirPath.split(",");

    directoryPaths.forEach((item, idx) => {
      const prevDirectoryPath = directoryPaths.slice(0, idx).join("/");

      __breadCrumb.push({
        link: "/" + (prevDirectoryPath ? prevDirectoryPath + "/" : "") + item,
        title: item,
      });
    });

    if (fileId) {
      const breadCrumbLength = __breadCrumb.length - 1;

      __breadCrumb[breadCrumbLength].link = __breadCrumb[breadCrumbLength].link + `?file=${fileId}`;
    }

    setBreadCrumb(__breadCrumb);
  };

  const errorToast = (message) =>
    toast({
      title: "Error",
      description: message ?? "Something went wrong, please try again",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

  const _getList = async () => {
    try {
      setListLoading(true);
      let data;
      let total;
      if (selectedUser?._id || toDate) {
        ({ data, total } = await listAPI(
          parseInt(page) - 1,
          pageSize,
          path,
          null,
          fromDate ? fromDate : null,
          toDate ? toDate : null,
          selectedUser?._id ? selectedUser._id : null
        ));
      } else {
        ({ data, total } = await listAPI(parseInt(page) - 1, pageSize, path));
      }

      const formattedList = data.map((item) => ({
        ...item,
        updatedAt: dayjs(item.updatedAt).format("MM/DD/YYYY h:mm a"),
      }));

      const formattedListSortedByFolder = formattedList.sort((a, b) =>
        a?.is_folder === b?.is_folder ? 0 : a?.is_folder ? -1 : 1
      );

      const sortedList = sortList(formattedListSortedByFolder);

      setTotalCount(total);
      setList(sortedList);
    } catch (e) {
      console.log(e?.response ?? e);
      errorToast(e?.response?.data?.error);
    } finally {
      setSelectedRows([]);
      setListLoading(false);
    }
  };

  const _getSearchList = async () => {
    try {
      setSearchLoading(true);
      const { data } = await listAPI(0, 20, null, searchText);
      setSearchList(data);
    } catch (e) {
      console.log(e?.response ?? e);
      errorToast(e?.response?.data?.error);
    } finally {
      setSearchLoading(false);
    }
  };

  const _getFolderList = async (current_folder) => {
    try {
      if (current_folder == "") current_folder = "setHome";
      let data;
      let total;
      ({ data, total } = await getFolders(current_folder));

      const formattedListSortedByFolder = data.sort((a, b) =>
        a?.is_folder === b?.is_folder ? 0 : a?.is_folder ? -1 : 1
      );

      setFolderList(formattedListSortedByFolder);
    } catch (e) {
      console.log(e);
    }
  };
  const uploadFiles = async (
    files,
    tagsFormatted,
    description,
    shortDescription,
    path,
    options,
    updateCurrentFile
  ) => {
    for (let i = 0; i < files.length; i++) {
      updateCurrentFile(files[i].name);
      const formData = new FormData();
      formData.append("Content-Type", files[i].type);
      formData.append("acl", "public-read");
      formData.append("file", files[i]);
      console.log(files[i].size, "THis is size");
      let filePath = path ? path?.split(",").join("/") : "";
      let fileExactPath = filePath + "/" + files[i].path;
      let newData = {
        filename: fileExactPath,
      };
      let response = await getPutUrl(newData);
      let uploadResponse = await uploadtoS3(response.url, files[i], options, files[i].type);
      let create_folder = async () => {
        let PAYLOAD = {
          name: files[i].name,
          tags: tagsFormatted,
          description: description,
          short_description: shortDescription,
          folder: path,
          filepath: fileExactPath,
        };
        await createFile(PAYLOAD);
      };
      create_folder();
    }
  };

  const value = {
    list,
    listLoading,
    _getList,
    searchText,
    setSearchText,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    _getSearchList,
    searchList,
    setSearchList,
    searchLoading,
    page,
    setPage,
    totalCount,
    path,
    setPath,
    navigate,
    fileId,
    breadCrumb,
    fileDetails,
    requestSort,
    sortConfig,
    pageSize,
    changePageSize,
    pageSizeOptions,
    findNextFile,
    findPreviousFile,
    filesInPage,
    totalPages,
    deleteList,
    setDeleteList,
    _deleteList,
    setSelectedUser,
    selectedUser,
    setSelectedRows,
    selectedRows,
    _getFolderList,
    folderList,
    uploadFiles,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
