import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Text,
  FormErrorMessage,
  FormHelperText,
  Grid,
  CloseButton,
  Progress,
  useToast,
  Box,
  Flex,
} from "@chakra-ui/react";
import { FcFile, FcUpload } from "react-icons/fc";
import { useCallback, useState, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Center, useColorModeValue, Icon } from "@chakra-ui/react";
import { AppContext } from "../context/AppContext";

const UploadButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { _getList, path, uploadFiles } = useContext(AppContext);
  const toast = useToast();

  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFile] = useState([]);
  const [shortDescError, setShortDescError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [currentFile, setCurrentFile] = useState("");
  const [LargeFileError, setLargeFileError] = useState(null);
  const validate = () => {
    const _descError = description?.trim()?.length === 0;
    const _shortDescError = shortDescription?.trim()?.length === 0;
    const _tagError = tags?.trim()?.length === 0;
    const _fileError = !files;

    setFileError(_fileError);
    setDescError(_descError);
    setShortDescError(_shortDescError);
    setTagError(_tagError);

    return _descError || _fileError || _shortDescError || _tagError;
  };

  const updateCurrentFile = (newFile) => {
    setCurrentFile(newFile);
  };
  const errorToast = (message) =>
    toast({
      title: "Error",
      description: message ?? "Something went wrong, please try again",
      status: "error",
      duration: 5000,
      isClosable: true,
    });

  const successToast = (message) =>
    toast({
      title: "Done",
      description: message ?? "Action is completed",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

  const uploadFile = async (e) => {
    e.preventDefault();

    const error = validate();

    if (error) return;

    setLoading(true);

    try {
      const tagsFormatted = tags
        .split(",")
        .map((tag) => tag.trim())
        .join(",");

      const options = {
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setUploadPercent(percent);
        },
      };
      await uploadFiles(
        files,
        tagsFormatted,
        description,
        shortDescription,
        path,
        options,
        updateCurrentFile
      );
    } catch (e) {
      console.log(e?.response ?? e);
      errorToast(e?.response?.data?.error);
    } finally {
      setLoading(false);
      _getList();
    }
  };

  return (
    <>
      <Button width={"140px"} onClick={onOpen} leftIcon={<FcUpload size={"16px"} />}>
        Upload
      </Button>
      <Modal scrollBehavior={"inside"} size={"lg"} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent height={"90vh"}>
          <ModalHeader>Upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={2} isInvalid={shortDescError}>
              <FormLabel>Caption</FormLabel>
              <Input
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
                placeholder="Caption.."
                autoFocus
              />
              <FormErrorMessage>Short description is required</FormErrorMessage>
            </FormControl>
            <FormControl mb={2} isInvalid={descError}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe.."
              />
              <FormErrorMessage>Description is required</FormErrorMessage>
            </FormControl>
            <FormControl mb={2} isInvalid={tagError}>
              <FormLabel>Tags</FormLabel>
              <Input
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                placeholder="Torcue, JPEG, Digital"
              />
              {tagError ? (
                <FormErrorMessage>Tags are required</FormErrorMessage>
              ) : (
                <FormHelperText>Enter tags as comma seperated</FormHelperText>
              )}
            </FormControl>
            <FormControl isInvalid={fileError}>
              <FormLabel>
                Select file{" "}
                {LargeFileError && (
                  <div className="border" style={{ color: "red" }}>
                    {LargeFileError}
                  </div>
                )}
              </FormLabel>

              {files.length > 0 ? (
                files.map((file) => (
                  <Grid
                    templateColumns={"auto auto auto 40px"}
                    gap={"8px"}
                    borderWidth={"1px"}
                    borderRadius={"5px"}
                    padding={"8px 12px"}
                    borderColor={
                      file.size >= 5.0 * 1024 * 1024 * 1024
                        ? () => {
                            setLargeFileError("Files must be less than 5GB ");
                            return "red";
                          }
                        : () => {
                            setLargeFileError(null);
                          }
                    }
                    width={"100%"}
                    alignItems={"center"}
                    marginBottom={"1px"}
                    key={file.name}
                  >
                    {console.log(files.size >= 1.0 * 1024 * 1024 * 1024, "File is ")}
                    <Icon size={"16px"} as={FcFile} mr={2} />
                    <Text align={"left"}>{file.name}</Text>
                    <Box></Box>
                    <CloseButton
                      variant={"outline"}
                      onClick={() => setFile(files.filter((f) => f.name !== file.name))}
                    />
                  </Grid>
                ))
              ) : (
                <FileUpload setFiles={setFile} />
              )}

              <FormErrorMessage>File is required</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} alignItems={"center"}>
            {loading ? (
              <Box
                width="25%"
                position="fixed"
                bottom="0"
                right="0"
                bg="white"
                p={4}
                borderRadius="8px"
              >
                <Text fontWeight="bold" mb={2}>
                  Uploading {uploadPercent}%<Text style={{ fontSize: "12px" }}>{currentFile}</Text>
                </Text>
                <Progress width="90%" colorScheme="green" value={uploadPercent} size="sm" />
              </Box>
            ) : (
              <div></div>
            )}

            <Button disabled={LargeFileError} onClick={uploadFile}>
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const FileUpload = ({ setFiles }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: null,
    multiple: true,
  });

  const inputProps = getInputProps({
    webkitdirectory: "true",
  });

  const dropText = isDragActive ? "Drop the file here.." : "Click to select file";

  const activeBg = useColorModeValue("gray.100", "gray.600");
  const borderColor = useColorModeValue(
    isDragActive ? "teal.300" : "gray.300",
    isDragActive ? "teal.500" : "gray.500"
  );

  return (
    <Center
      p={10}
      cursor="pointer"
      bg={isDragActive ? activeBg : "transparent"}
      _hover={{ bg: activeBg }}
      transition="background-color 0.2s ease"
      borderRadius={4}
      border="3px dashed"
      borderColor={borderColor}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Icon size={"16px"} as={FcFile} mr={2} />
      <Text color={"gray.500"}>{dropText}</Text>
    </Center>
  );
};

export default UploadButton;
