import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const BreadCrumb = () => {
  const { breadCrumb } = useContext(AppContext);

  return (
    <Breadcrumb>
      {breadCrumb.map((item, idx) => (
        <BreadcrumbItem key={idx}>
          <BreadcrumbLink to={item.link} as={Link}>
            {item.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
export default BreadCrumb;
