import {
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    FormControl,
    FormLabel,
    Textarea,
    Text,
    FormErrorMessage,
    FormHelperText,
    useToast,
    Tooltip,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { useState, useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { editFile } from "../api";

const EditButton = (fileDetails) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { _getList } = useContext(AppContext);
    const toast = useToast();

    const [id, setId] = useState('');
    const [tags, setTags] = useState('');
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [shortDescError, setShortDescError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [tagError, setTagError] = useState(false);

    useEffect(() => {
        setTags(fileDetails.fileDetails?.tags?.join(", "))
        setDescription(fileDetails.fileDetails.description)
        setShortDescription(fileDetails.fileDetails.short_description)
        setId(fileDetails.fileDetails._id)
    }, [fileDetails]);

    const validate = () => {
        const _descError = description?.trim()?.length === 0;
        const _shortDescError = shortDescription?.trim()?.length === 0;
        const _tagError = tags?.trim()?.length === 0;

        setDescError(_descError);
        setShortDescError(_shortDescError);
        setTagError(_tagError);

        return _descError || _shortDescError || _tagError;
    };

    const errorToast = (message) =>
        toast({
            title: "Error",
            description: message ?? "Something went wrong, please try again",
            status: "error",
            duration: 5000,
            isClosable: true,
        });

    const successToast = (message) =>
        toast({
            title: "Done",
            description: message ?? "Action is completed",
            status: "success",
            duration: 3000,
            isClosable: true,
        });

    const saveFile = async (e) => {

        e.preventDefault();

        const error = validate();

        if (error) return;

        setLoading(true);

        try {

            const tagsFormatted = tags
                .split(",")
                .map((tag) => tag.trim())
                .join(",");

            await editFile(id, tagsFormatted, description, shortDescription);

            setLoading(false);
            onClose();
            successToast("File Edited");
        } catch (e) {
            setLoading(false);
            console.log(e?.response ?? e);
            onClose();
            errorToast(e?.response?.data?.error);
        } finally {
            _getList();
        }
    };

    return (
        <>
            <Tooltip label="Edit file" aria-label="Edit file">
                <Button
                    size="sm"
                    onClick={onOpen}
                >
                    <FaEdit size={"18px"} />
                </Button>
            </Tooltip>
            <Modal
                scrollBehavior={"inside"}
                size={"lg"}
                isOpen={isOpen}
                onClose={onClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent height={"60vh"}>
                    <ModalHeader>Edit File</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={2} isInvalid={shortDescError}>
                            <FormLabel>Caption</FormLabel>
                            <Input
                                value={shortDescription}
                                onChange={(e) => setShortDescription(e.target.value)}
                                placeholder="Caption.."
                                autoFocus
                            />
                            <FormErrorMessage>Short description is required</FormErrorMessage>
                        </FormControl>
                        <FormControl mb={2} isInvalid={descError}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Describe.."
                            />
                            <FormErrorMessage>Description is required</FormErrorMessage>
                        </FormControl>
                        <FormControl mb={2} isInvalid={tagError}>
                            <FormLabel>Tags</FormLabel>
                            <Input
                                value={tags}
                                onChange={(e) => setTags(e.target.value)}
                                placeholder="Torcue, JPEG, Digital"
                            />
                            {tagError ? (
                                <FormErrorMessage>Tags are required</FormErrorMessage>
                            ) : (
                                <FormHelperText>Enter tags as comma seperated</FormHelperText>
                            )}
                        </FormControl>
                    </ModalBody>
                    <ModalFooter alignItems={"right"}>
                        <Button isLoading={loading} onClick={saveFile}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditButton;
