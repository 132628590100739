import React, { createContext, useState, useEffect } from "react";

const initialAuthState = {
  access_token: "",
};

const ACCESS_TOKEN_STRING = "access_token";

export const AuthContext = createContext({
  authState: initialAuthState,
});

export const AuthContextProvider = ({ children }) => {
  const [authState, setAuthState] = useState(initialAuthState);
  const [storedUserData, setStoredUserData] = useState('');
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    photo: '',
    type: '',
    access: ''
  });

  useEffect(() => {
    if (storedUserData) {
      setUserData(storedUserData);
    }
  }, [storedUserData]);


  const updateAuthStateContext = (access_token) => {
    window.localStorage.setItem(ACCESS_TOKEN_STRING, access_token);
    setAuthState({
      access_token,
    });
  };

  const logOutUser = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_STRING);
    window.localStorage.removeItem('userData');
    setAuthState({
      ...initialAuthState,
    });
  };

  const checkAuthState = () => {
    const access_token = window.localStorage.getItem(ACCESS_TOKEN_STRING);
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    setStoredUserData(storedUserData)
    if (!access_token) {
      logOutUser();
    } else {
      logInUser(access_token);
    }
  };

  const logInUser = (access_token) => {
    updateAuthStateContext(access_token);
  };

  const setUser = (_userData) => {
    window.localStorage.setItem('userData', JSON.stringify(_userData))
    let user_data = JSON.parse(window.localStorage.getItem('userData'));
    setStoredUserData(user_data)
  };

  const isLoggedIn = authState.access_token !== "";

  const value = {
    authState,
    isLoggedIn,
    checkAuthState,
    logInUser,
    logOutUser,
    setUser,
    userData,
    setUserData,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
