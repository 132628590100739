import {
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useColorMode,
  Heading,
  VStack,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  useToast,
  Input,
  Button,
} from "@chakra-ui/react";
import { MdMoreVert } from "react-icons/md";
import TableSkeleton from "./TableSkeleton";
import { FcFolder } from "react-icons/fc";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import { AuthContext } from "../context/AuthContext";
import noPlaceHolder from "../assets/noplaceholder.png";
import { deleteFile, renameFile, folderStatus, hardDelete } from "../api";
import fileTypes from "../constants/fileTypes";
import ReactPlayer from "react-player/lazy";
const StorageTable = ({ isLoading }) => {
  const { colorMode } = useColorMode();
  const {
    navigate,
    requestSort,
    sortConfig,
    list,
    totalCount,
    pageSize,
    _getList,
    selectedRows,
    setSelectedRows,
  } = useContext(AppContext);

  const { userData } = useContext(AuthContext);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const toast = useToast();

  const [editingItemId, setEditingItemId] = useState(null);
  const [newItemName, setNewItemName] = useState("");

  const handleRenameClick = (itemId, name) => {
    setEditingItemId(itemId);
    setNewItemName(name);
  };

  const handleSaveClick = async (id, name) => {
    try {
      setRenameLoading(true);
      await renameFile(id, name);
    } catch (e) {
      console.log(e?.response ?? e);
    } finally {
      setRenameLoading(false);
      _getList();
    }
    setEditingItemId(null);
  };

  const handleCancelClick = () => {
    setEditingItemId(null);
  };

  const deleteAction = async (id) => {
    try {
      setDeleteLoading(true);
      let status = await folderStatus(id);
      if (status.data.length === 0) {
        if (userData.type === "admin") await hardDelete(id);
        else await deleteFile(id);
      } else {
        toast({
          title: "Error",
          description: "Folder is not empty",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.log(e?.response ?? e);
    } finally {
      setDeleteLoading(false);
      _getList();
    }
  };

  if (isLoading || list?.length) {
    return (
      <Table mt={"12px"} size="md">
        {!isLoading && (
          <TableCaption mt={"8px"} placement="top">
            {`${totalCount > pageSize ? `${pageSize} of ` : ""}${totalCount} item${
              totalCount > 1 ? "s" : ""
            }`}
          </TableCaption>
        )}
        <Thead>
          <Tr>
            <Th
              onClick={() => requestSort("name")}
              width={"60%"}
              cursor={"pointer"}
              padding={"0px"}
              _active={{ transform: "translateY(-1px)" }}
            >
              <HStack padding={"16px 12px"}>
                <Text>Name</Text>
                {sortConfig?.key === "name" &&
                  (sortConfig?.direction === "asc" ? (
                    <FaSortDown />
                  ) : sortConfig?.direction === "desc" ? (
                    <FaSortUp />
                  ) : null)}
              </HStack>
            </Th>
            <Th
              onClick={() => requestSort("updatedAt")}
              width={"20%"}
              cursor={"pointer"}
              padding={"0px"}
              _active={{ transform: "translateY(-1px)" }}
            >
              <HStack padding={"16px 12px"}>
                <Text>Last Modified</Text>
                {sortConfig?.key === "updatedAt" &&
                  (sortConfig?.direction === "asc" ? (
                    <FaSortDown />
                  ) : sortConfig?.direction === "desc" ? (
                    <FaSortUp />
                  ) : null)}
              </HStack>
            </Th>
            {userData.type === "admin" ? (
              <Th
                onClick={() => requestSort("userName")}
                width={"20%"}
                cursor={"pointer"}
                padding={"0px"}
                _active={{ transform: "translateY(-1px)" }}
              >
                <HStack padding={"16px 12px"}>
                  <Text>User</Text>
                  {sortConfig?.key === "userName" &&
                    (sortConfig?.direction === "asc" ? (
                      <FaSortDown />
                    ) : sortConfig?.direction === "desc" ? (
                      <FaSortUp />
                    ) : null)}
                </HStack>
              </Th>
            ) : null}
            <Th width={"1%"}></Th>
          </Tr>
        </Thead>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <Tbody>
            {list.map((item) => (
              <Tr
                _hover={{ bg: colorMode === "light" ? "gray.50" : "#141214" }}
                key={item._id}
                cursor={"pointer"}
                onClick={() => {
                  if (selectedRows.includes(item._id)) {
                    setSelectedRows(selectedRows.filter((id) => id !== item._id));
                  } else {
                    setSelectedRows([...selectedRows, item._id]);
                  }
                }}
                bg={selectedRows.includes(item._id) ? "gray.100" : ""}
              >
                <Td
                  alignItems={"center"}
                  padding={"16px 12px"}
                  _hover={{ textDecoration: "underline" }}
                >
                  {console.log(item, "testitem")}
                  <HStack>
                    {
                      item.is_folder ? (
                        <FcFolder size={"18px"} />
                      ) : fileTypes[
                          item?.image_path?.split(/[#?]/)[0]?.split(".")?.pop()?.trim()
                        ] === "video" ? (
                        <ReactPlayer width={"100px"} height={"100px"} url={item.imageUrl} />
                      ) : (
                        <Image
                          loading={"lazy"}
                          maxWidth={"100px"}
                          maxHeight={"100px"}
                          src={item.previewUrl ? item.previewUrl : noPlaceHolder}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "";
                          }}
                          objectFit="cover"
                        />
                      )

                      // (

                      // )
                    }
                    {editingItemId === item._id ? (
                      <Input
                        ml={"8px"}
                        value={newItemName}
                        onChange={(e) => setNewItemName(e.target.value)}
                      />
                    ) : (
                      <Text
                        onClick={() => {
                          editingItemId === null && navigate(item);
                        }}
                        ml={"8px"}
                      >
                        {item.name}
                      </Text>
                    )}
                  </HStack>
                </Td>
                <Td padding={"16px 12px"}>{item.updatedAt}</Td>
                {userData.type === "admin" ? <Td padding={"16px 12px"}>{item.userName}</Td> : null}
                {editingItemId === null && (
                  <Td padding={"16px 12px"}>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<MdMoreVert />}
                        size={"sm"}
                        variant={"ghost"}
                      />
                      <MenuList>
                        {item.is_folder ? (
                          userData.access || userData.type === "admin" ? (
                            <MenuItem
                              onClick={() => handleRenameClick(item._id, item.name)}
                              isDisabled={renameLoading}
                            >
                              {renameLoading ? "Renaming..." : "Rename"}
                            </MenuItem>
                          ) : null
                        ) : (
                          <MenuItem onClick={() => window.open(item.imageUrl, "_blank")}>
                            Download
                          </MenuItem>
                        )}
                        {userData.access || userData.type === "admin" ? (
                          <MenuItem
                            onClick={() => deleteAction(item._id)}
                            isDisabled={deleteLoading}
                          >
                            {deleteLoading ? "Deleting..." : "Delete"}
                          </MenuItem>
                        ) : null}
                      </MenuList>
                    </Menu>
                  </Td>
                )}
                {editingItemId === item._id && (
                  <>
                    <Td>
                      <HStack>
                        <Button onClick={() => handleSaveClick(item._id, newItemName)}>Save</Button>
                        <Button onClick={() => handleCancelClick()}>Cancel</Button>
                      </HStack>
                    </Td>
                  </>
                )}
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
    );
  } else {
    return (
      <VStack height={"50vh"} justifyContent="center">
        <Heading fontSize={"16px"} fontWeight={"semibold"}>
          This folder is empty
        </Heading>
        <Text fontSize={"14px"}>Start by creating a folder or uploading a file</Text>
      </VStack>
    );
  }
};

export default StorageTable;
