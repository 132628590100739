import React, { useState, useContext, useEffect } from "react";
import {
    Box,
    Button,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Avatar,
} from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { AppContext } from "../context/AppContext";
import { AuthContext } from "../context/AuthContext";
import { getUsers } from "../api";

function UserFilter() {
    const { userData } = useContext(AuthContext);
    const {
        selectedUser,
        setSelectedUser,
    } = useContext(AppContext);
    const [users, setUsers] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getUsers();
                const data =
                    response.data?.map((user) => ({
                        _id: user._id,
                        email: user.email,
                        photo: user.photo?.toString() || "",
                    })) || [];
                setUsers(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const handleUserSelect = (user) => {
        setSelectedUser(user);

    };

    const handleClearUser = () => {
        setSelectedUser(null);
    };

    return userData.type === 'admin' ? (
        <HStack>
            {users && (
                <Menu>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        position="relative"
                        zIndex='1'
                    >
                        {selectedUser ? (
                            <>
                                <Avatar
                                    name={selectedUser.email}
                                    src={selectedUser.photo}
                                    mr="2"
                                    size="sm"
                                />
                                {selectedUser.email}
                            </>
                        ) : (
                            "Select user..."
                        )}
                    </MenuButton>
                    {selectedUser ? (
                        <Button
                            variant="ghost"
                            size="xs"
                            ml="2"
                            onClick={handleClearUser}
                            zIndex="3"
                        >
                            <CloseIcon />
                        </Button>
                    ) : (
                        null
                    )}
                    <Box
                        as={MenuList}
                        maxHeight="250px"
                        overflowY="auto"
                        boxShadow="lg"
                        borderRadius="md"
                        py="1"
                        zIndex="1"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {users.map((user) => (
                            <MenuItem key={user._id} onClick={() => handleUserSelect(user)}>
                                <Avatar name={user.email} src={user.photo} mr="2" size="sm" />
                                {user.email}
                            </MenuItem>
                        ))}
                    </Box>
                </Menu>
            )}
        </HStack>
    ) : (
        <></>
    );
}

export default UserFilter;
