import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Input,
    List,
    ListItem,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Text,
    useDisclosure,
    Avatar,
    HStack,
    Tooltip
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { getUsers } from "../api";
import "./css/multiSelect.css"


const MultiSelect = ({ multiselectData, selectedData }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchText, setSearchText] = useState("");
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getUsers();
                const data =
                    response.data?.map((user) => ({
                        key: user._id,
                        email: user.email,
                        name: user.email,
                        href: "#",
                        src: user.photo?.toString() || "",
                    })) || [];
                setUsers(data);
                if (selectedData) {
                    setSelectedUsers(selectedData)
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const handleUserSelection = (user) => {
        if (!selectedUsers.includes(user)) {
            setSelectedUsers([...selectedUsers, user]);
            multiselectData([...selectedUsers, user])
        }
    };

    const handleUserDeselection = (user) => {
        const updatedSelectedUsers = selectedUsers.filter(
            (selectedUser) => selectedUser !== user
        );
        setSelectedUsers(updatedSelectedUsers);
        multiselectData(updatedSelectedUsers);
    };

    const filteredUsers = users.filter(
        (user) =>
            !selectedUsers.some((selectedUser) => selectedUser.key === user.key) &&
            user.email.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <Box display="flex" flexWrap="wrap" alignItems="center">
                <Box flex="1">
                    <Menu isOpen={isOpen} onClose={onClose}>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            variant="outline"
                            colorScheme="grey"
                            onClick={onOpen}
                        >
                            Choose users
                        </MenuButton>
                        <MenuList>
                            <Stack>
                                <Box px="3">
                                    <Input
                                        placeholder="Search users"
                                        size="sm"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </Box>
                                <List maxH="240px" overflowY="auto">
                                    {filteredUsers.map((user) => (
                                        <ListItem key={user.key}>
                                            <MenuItem
                                                onClick={() => {
                                                    handleUserSelection(user);
                                                    onClose();
                                                }}
                                            >
                                                <HStack>
                                                    <Avatar name={user.email} src={user.src} size="sm" />
                                                    <Text>{user.email}</Text>
                                                </HStack>
                                            </MenuItem>
                                        </ListItem>
                                    ))}
                                </List>
                            </Stack>
                        </MenuList>
                    </Menu>
                </Box>
            </Box>
            {selectedUsers.length > 0 && (
                <Box mt="2">
                    <Text fontWeight="bold">Selected users:</Text>
                    <Box mt="1">
                        <Stack direction="row" flexWrap="wrap" spacing="2">
                            {selectedUsers.map((user) => (
                                <Box key={user.key}>
                                    <AvatarButton
                                        user={user}
                                        onClick={() => handleUserDeselection(user)}
                                    />
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                </Box>
            )}
        </>
    );
};

const AvatarButton = ({ user, onClick }) => (
    <Tooltip label={user.email} aria-label="User email">
        <Avatar name={user.email} src={user.src} size="sm" onClick={onClick} style={{ cursor: "pointer" }}/>
    </Tooltip>
);

export default MultiSelect;
