import {
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    HStack,
    useColorMode,
    Spinner,
} from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import { moveFile } from "../api";
import { AppContext } from "../context/AppContext";
import { FaTrash } from "react-icons/fa";
import { FcFolder } from "react-icons/fc";

const Delete = () => {
    const { colorMode } = useColorMode();
    const toast = useToast();
    const { _getFolderList, folderList, selectedRows, _getList } = useContext(AppContext);
    const [currentFolder, setCurrentFolder] = useState('');
    const [previousFolder, setPreviousFolder] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        _getFolderList(currentFolder)
    }, []);

    const navigate = (name) => {
        let Folder = ''
        if (currentFolder == '') {
            Folder = name
        }
        else {
            Folder = currentFolder + ',' + name
        }
        let prev_folder = previousFolder
        prev_folder.push(name)
        setPreviousFolder(prev_folder)
        setCurrentFolder(Folder)
        _getFolderList(Folder)
    }

    const back = () => {
        console.log(previousFolder)
        let lastIndex = previousFolder.length - 1
        previousFolder.splice(lastIndex, 1)
        setPreviousFolder(previousFolder)
        setCurrentFolder(previousFolder)
        let string = previousFolder.join(',');
        console.log('string', string)
        _getFolderList(string)
    }

    const successToast = (message) =>
        toast({
            title: "Done",
            description: message ?? "Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
        });

    const move = async () => {
        try {
            setIsLoading(true)
            await moveFile(selectedRows, currentFolder)
        } catch (e) {
            console.log(e)
        }finally{
            setIsLoading(false)
            onClose()
            _getList()
        }
    }

    return (
        <>
            <Button onClick={onOpen}>
                Move
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW="500px" h="500px">
                    <ModalCloseButton />
                    <ModalBody overflow="auto">
                        <Table mt={"12px"} size="md">
                            <Thead>
                                <Tr>
                                    <Th
                                        width={"60%"}
                                        cursor={"pointer"}
                                        padding={"0px"}
                                        _active={{ transform: "translateY(-1px)" }}
                                        onClick={() => {
                                            back()
                                        }}
                                    >
                                        <HStack padding={"16px 12px"}>
                                            <Text>Back</Text>
                                        </HStack>
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {folderList.map((item) => (
                                    <Tr
                                        _hover={{ bg: colorMode === "light" ? "gray.50" : "#141214" }}
                                        key={item._id}
                                        cursor={"pointer"}
                                    >
                                        <Td
                                            display={"flex"}
                                            alignItems={"center"}
                                            padding={"16px 12px"}
                                            _hover={{ textDecoration: "underline" }}
                                            onClick={() => {
                                                navigate(item.name)
                                            }}
                                        >
                                            <FcFolder size={"10px"} />
                                            <Text ml={"8px"}>{item.name}</Text>
                                        </Td>
                                    </Tr>
                                ))}
                                {folderList.length <= 0 && (
                                    <Tr>
                                        <Td
                                            display={"flex"}
                                            alignItems={"center"}
                                            padding={"16px 12px"}
                                        >
                                            This Folder Is Empty
                                        </Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            move()
                        }}>
                            {isLoading ? <Spinner size="sm" /> : 'Move Here'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Delete;
