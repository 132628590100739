import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { signInWithGoogle } from "../api";
import { AuthContext } from "../context/AuthContext";
import "./css/GoogleSignInButton.css"

const jwt = require('jsonwebtoken');


function GoogleSignInButton() {
    const { logInUser, setUser } = useContext(AuthContext);
    const [auth, setAuth] = useState(null);
    const handleSignInWithGoogle = async () => {
        try {
            const googleUser = await auth.signInWithPopup(
                new firebase.auth.GoogleAuthProvider()
            );
            const { idToken } = googleUser.credential;
            const res = await signInWithGoogle(idToken);
            if (res?.token) {
                logInUser(res?.token);
                const decodedToken = jwt.decode(res?.token);
                const _userData = {
                    name: decodedToken.name,
                    photo: decodedToken.photo,
                    email: decodedToken.email,
                    type: decodedToken.type,
                    access: decodedToken.access,
                };
                setUser(_userData);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleLoadFirebase = () => {
        const firebaseConfig = {
            apiKey: "AIzaSyBi-x6M_CG15rrAmWRqRdTpkMmq4SR383A",
            authDomain: "storeme-f9119.firebaseapp.com",
            projectId: "storeme-f9119",
            storageBucket: "storeme-f9119.appspot.com",
            messagingSenderId: "1011305374815",
            appId: "1:1011305374815:web:2acb52d7d9af901fb0e7ea",
            measurementId: "G-Q7CPXTM1SR"
        };
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }
        setAuth(firebase.auth());
    };

    useEffect(() => {
        handleLoadFirebase();
    }, []);

    return (
        <div>
            {auth ? (
                <button className="google-btn" onClick={handleSignInWithGoogle}>
                    <div className="google-icon-wrapper">
                        <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="Google logo" />
                    </div>
                    <span>Sign in with Google</span>
                </button>

            ) : (
                <p>Loading Firebase API...</p>
            )}
        </div>
    );
}

export default GoogleSignInButton;
