import {
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    HStack,
    Image,
    useColorMode,
    Spinner,
    Badge,
} from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import { hardDelete } from "../api";
import { AppContext } from "../context/AppContext";
import { FaTrash } from "react-icons/fa";


const Delete = () => {
    const { colorMode } = useColorMode();
    const toast = useToast();
    const { deleteList, _deleteList } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        _deleteList()
    }, []);

    const successToast = (message) =>
        toast({
            title: "Done",
            description: message ?? "Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
        });

    const deleteFile = async (id) => {
        try {
            setLoading(true);
            await hardDelete(id);
            successToast()
        } catch (e) {
            toast({
                title: "Error",
                description:
                    e?.response?.data?.error ?? "Something went wrong, please try again",
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        } finally {
            _deleteList()
            setLoading(false);
        }
    }

    return (
        <>
            <Button onClick={onOpen} leftIcon={<FaTrash />}>
                Delete
                {deleteList.length > 0 && (
                    <Badge ml={2} colorScheme="" fontSize="md">
                        {deleteList.length}
                    </Badge>
                )}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW="1200px" h="500px" overflow="auto">
                    <ModalHeader>Delete</ModalHeader>
                    <ModalCloseButton />
                    <Table mt={"12px"} size="md">
                        <Thead>
                            <Tr>
                                <Th
                                    width={"60%"}
                                    cursor={"pointer"}
                                    padding={"0px"}
                                    _active={{ transform: "translateY(-1px)" }}
                                >
                                    <HStack padding={"16px 12px"}>
                                        <Text>Name</Text>
                                    </HStack>
                                </Th>
                                <Th
                                    width={"20%"}
                                    cursor={"pointer"}
                                    padding={"0px"}
                                    _active={{ transform: "translateY(-1px)" }}
                                >
                                    <HStack padding={"16px 12px"}>
                                        <Text>Last Modified</Text>
                                    </HStack>
                                </Th>
                                <Th
                                    width={"20%"}
                                    cursor={"pointer"}
                                    padding={"0px"}
                                    _active={{ transform: "translateY(-1px)" }}
                                >
                                    <HStack padding={"16px 12px"}>
                                        <Text>User</Text>
                                    </HStack>
                                </Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {deleteList.map((item) => (
                                <Tr
                                    _hover={{ bg: colorMode === "light" ? "gray.50" : "#141214" }}
                                    key={item._id}
                                    cursor={"pointer"}
                                >
                                    <Td
                                        display={"flex"}
                                        alignItems={"center"}
                                        padding={"16px 12px"}
                                        _hover={{ textDecoration: "underline" }}
                                    >

                                        <Image
                                            loading={"lazy"}
                                            maxWidth={"100px"}
                                            maxHeight={"100px"}
                                            src={item.previewUrl}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "";
                                            }}
                                            objectFit="cover"
                                        />
                                        <Text ml={"8px"}>{item.name}</Text>
                                    </Td>
                                    <Td padding={"16px 12px"}>{item.updatedAt}</Td>
                                    <Td padding={"16px 12px"}>{item.userName}</Td>
                                    <Td padding={"16px 12px"}>
                                        <button onClick={() => deleteFile(item._id)}>
                                            {loading ? <Spinner size="sm" /> : <FaTrash />}
                                        </button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Delete;
