import { Box } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "../components/Header";
import Home from "../screens/Home";
import { AuthContext } from "../context/AuthContext";
import Login from "../screens/Login";
import { AppContextProvider } from "../context/AppContext";

const RootRoute = () => {
  const { isLoggedIn, checkAuthState } = useContext(AuthContext);

  useEffect(() => {
    checkAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Router>
      
      {isLoggedIn ? (
        <AppContextProvider>
          <Header />
          <Box px={"80px"} pt={"90px"} pb={"40px"}>
            <Route component={Home} />
          </Box>
          </AppContextProvider>
      ) : (
        <>
          <Header />
          <Box px={"80px"} pt={"90px"} pb={"40px"}>
            <Route component={Login} />
          </Box>
        </>
      )}
      
    </Router>
  );
};

export default RootRoute;
